<template>
	<div id="app">
        <div id="chart-container">
            <DxChart
				id="chart"
                :data-source="preview.dataList"
                :common-series-settings="data.commonSeriesSettings"
                :series="data.series"
                :legend="data.legend"
			>

			<DxSeries
				v-for="chatDataSeries in preview.series"
				:key="chatDataSeries.value"
				:value-field="chatDataSeries.valueField"
				:name="chatDataSeries.name"
			/>
		
			</DxChart>
			<!-- :tooltip="tooltipSettings" -->
			<!-- :title="data.title" -->
			<!-- {{ preview.dataList }}
			<template v-if="!preview.rotated&&(preview.chartType === 'bar'||preview.chartType === 'stackedbar' || preview.chartType === 'fullstackedbar')">
				<DxChart
					ref="chart"
					:data-source="preview.dataList"
					paleDxCharttte="ocean"
					>
					<DxCommonSeriesSettings
						:type="preview.chartType"
						argument-field="category"
					/>
					<DxCommonAxisSettings>
						<DxGrid visible="true"/>
					</DxCommonAxisSettings>
					<DxSeries
						v-for="chatDataSeries in chart.series"
						:key="chatDataSeries.value"
						:value-field="chatDataSeries.value"
						:name="chatDataSeries.name"
						:color="chatDataSeries.color"
					/>
					<DxMargin bottom="0"/>
					<DxArgumentAxis
						allow-decimals="false"
						axis-division-factor="60"
					>
						<DxLabel>
						<DxFormat type="decimal"/>
						</DxLabel>
					</DxArgumentAxis>
					
					<DxLegend
						vertical-alignment="top"
						horizontal-alignment="right"
					/>
					<DxExport enabled="false"/>
					<DxTooltip enabled="true"/>
					<DxAdaptiveLayout keep-labels="true" width="100"/> 
				</DxChart>
			</template> -->
        </div>
    </div>
  </template>
  
  <script>
  import {
        DxChart,
        DxSeries,
        DxArgumentAxis,
        DxCommonSeriesSettings,
        DxCommonAxisSettings,
        DxGrid,
        DxExport,
        DxLegend,
        DxMargin,
        DxTooltip,
        DxLabel,
        DxFormat,
        DxAdaptiveLayout
    } from 'devextreme-vue/chart';
    import { DxButton } from 'devextreme-vue/button';
    import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
    import ModalWidgetSetting from '@/pages/report/dashboard/modal-widget-setting';
    import ModalChartEnlarge from '@/pages/report/dashboard/modal-chart-enlarge';
    export default {
        components: {
            DxChart,
            DxSeries,
            DxArgumentAxis,
            DxCommonSeriesSettings,
            DxCommonAxisSettings,
            DxGrid,
            DxExport,
            DxLegend,
            DxMargin,
            DxTooltip,
            DxLabel,
            DxFormat,
            DxAdaptiveLayout,
            DxButton,
            DxPopup,
            DxToolbarItem,
            ModalWidgetSetting,
            ModalChartEnlarge

        },
	props: {
		preview: Array,
	},
	watch: {
	},
	data() {
	  return {
		title: '',
		textSize: 0,
		limitLength: 50,
		stylingMode: 'outlined', //[outlined, filled, underlined]
		el: '#app',
		data: {
			// data: [
			// 	{ category: "Category 1", value1: 10, value2: 15, value3: 20 },
			// 	{ category: "Category 2", value1: 5, value2: 10, value3: 15 },
			// 	{ category: "Category 3", value1: 15, value2: 5, value3: 10 }
			// ],
			commonSeriesSettings: {
				argumentField: "timestamp",
				type: "fullstackedbar" // 100% 누적 세로 막대
				//type: "fullstackedrotatedbar" // 100% 누적 가로 막대
				//type: "stackedbar" // 누적 세로 막대
				//type: "stackedrotatedbar" // 누적 가로 막대
				//type: "rotatedbar" // 가로 막대
				//type: "bar" // 세로 막대
				//type: "line" // 라인 차트
				//type: "pie" // 파이 차트
			},
			// series: [
			// 	{ valueField: "value1", name: "인입콜" },
			// 	{ valueField: "value2", name: "포기콜" },
			// 	{ valueField: "value3", name: "응답콜" }
			// ],
			legend: {
				verticalAlignment: "bottom",
				horizontalAlignment: "center",
				itemTextPosition:"right"
			},
			// title: "DevExtreme FullStackedRotatedBar Chart Example"
			// tooltipSettings: {
			// 	enabled: true,
			// 	customizeTooltip: function (arg) {
			// 		return {
			// 			text: arg.valueText
			// 		};
			// 	}
			// }
		},
		chart: {
			type: 'stackedbar',//bar
			series:[],
			width:0,
			height:0,
		},
	  }
	},
	computed: {
	},
	methods: {
	//   onCheckTextSize(e) {
	// 	const text = e.event.target.value;
	// 	this.textSize = text.length;
	//   }
	},
	created() {
		// console.log(this.widgetPreview);
	},
	mounted() {
	},
	destroyed() {
	},
  }
  </script>

<style>
#chart {
	width: 830px;
  	height: 450px;
}
</style>